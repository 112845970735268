import React from "react"
import MainHeading from "@common/MainHeading/MainHeading"
import PageHeading from "@common/PageHeading/PageHeading"
import "./PrivacyPolicy.scss"
import CircleBLur from "@common/CircleBlur/CircleBlur"
import { Link } from "gatsby"
import { GLOBAL_APPLICATION_EXTERNAL_LINKS, GLOBAL_APPLICATION_INTERNAL_LINKS } from "@links"

const PrivacyPolicy = () => (
  <div className="privacy-policy">
    <CircleBLur width="635px" left="-305px" height="635px" />
    <MainHeading
      heading="Privacy policy for Impressit"
      mainParagraph="One of our main priorities is the privacy of our visitors.
                        This Privacy Policy document contains types of information that is collected
                         and recorded by Impressit and how we use it."
      secondParagraph={
        <>
          If you have additional questions or require more information about our
          Privacy Policy, do not hesitate to contact us through email at{" "}
          <Link
            to="mailto:team@impressit.io"
            className="privacy-policy__highlited"
          >
            team@impressit.io
          </Link>
        </>
      }
    />
    <div className="privacy-policy__info-container">
      <PageHeading
        heading="Log files"
        mainParagraph="Impressit follows a standard procedure of using log files. These files log visitors when they
                visit websites. The information collected by log files includes internet protocol (IP) addresses, browser type,
                Internet Service Provider (ISP),
                date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any personally
                identifiable information.
                The purpose of the information is for analyzing trends, administering the site, tracking users’ movement on the website,
                 and gathering demographic information."
      />
    </div>
    <div className="privacy-policy__info-container">
      <PageHeading
        heading="Cookies and web beacons"
        mainParagraph={
          <>
            Like any other website, Impressit uses ‘cookies’. They are used to
            store information including{" "}
            <Link
              className="privacy-policy__highlited"
              to={GLOBAL_APPLICATION_INTERNAL_LINKS.COOKIE_POLICY}
            >
              visitors’ preferences
            </Link>{" "}
            and the pages on the website that the person accessed or visited.
            The information is used to optimize the users’ experience by
            customizing our web page content based on visitors’ browser type
            and/or other information.
          </>
        }
      />
    </div>
    <div className="privacy-policy__info-container">
      <PageHeading
        heading="Privacy policies"
        mainParagraph={
          <>
            You may consult this list to find the Privacy Policy for each of the
            advertising partners of Impressit. Our Privacy Policy was created
            with the help of the{" "}
            <Link
              to={GLOBAL_APPLICATION_EXTERNAL_LINKS.PRIVACY_POLICY}
              target="_blank"
              className="privacy-policy__highlited"
              rel="noreferrer"
            >
              Privacy Policy Generator
            </Link>
            .
          </>
        }
        secondParagraph="Third-party ad servers or ad networks use technologies like cookies, JavaScript, or Web Beacons that are used in their
                respective advertisements and links that appear on Impressit, which are sent directly to users’ browser. They automatically receive
                 your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or
                 to personalize the advertising content that you see on websites that you visit."
        thirdParagraph="Note that Impressit has no access to or control over these cookies that are used by third-party advertisers."
      />
    </div>
    <div className="privacy-policy__info-container">
      <CircleBLur width="635px" right="-635px" height="635px" />
      <PageHeading
        heading="Third party privacy policies"
        mainParagraph="Impressit’s Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult
                the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices
                and instructions about how to opt-out of certain options.
                You may find a complete list of these Privacy Policies and their links here: Privacy Policy Links."
        secondParagraph="You can choose to disable cookies through your browser options. To get more information about cookie management
                 with specific web browsers, check the browsers’ respective websites."
      />
    </div>
    <div className="privacy-policy__info-container">
      <PageHeading
        heading="Children’s information"
        mainParagraph="Another part of our priority is adding protection for children while using the internet. We encourage parents
                and guardians to observe, participate in, and/or monitor and guide their online activity."
        secondParagraph="Impressit does not knowingly collect any Personal Identifiable Information from children under the age of 13.
                If you think that your child provided this kind of information on our website, we strongly encourage you
                to contact us immediately, and we will do our best to promptly remove such information from our records."
      />
    </div>
    <div className="privacy-policy__info-container">
      <PageHeading
        heading="Online privacy policy only"
        mainParagraph="This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards
                to the information that they shared and/or collect in Impressit. This policy is not applicable to any information
                collected offline or via channels other than this website."
      />
    </div>
    <div className="privacy-policy__info-container">
      <PageHeading
        heading="Consent"
        mainParagraph="By using our website, you hereby consent to our Privacy Policy and agree to its Terms and Conditions."
      />
    </div>
  </div>
)

export default PrivacyPolicy
